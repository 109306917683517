import * as React from 'react';
import { Children, cloneElement, isValidElement, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate, useGetIdentity } from 'ra-core';
import { Tooltip, IconButton, Menu, Button, Avatar, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
var defaultIcon = React.createElement(AccountCircle, null);
var useStyles = makeStyles(function (theme) { return ({
    user: {},
    userButton: {
        textTransform: 'none',
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
}); }, { name: 'RaUserMenu' });
var AnchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};
var TransformOrigin = {
    vertical: 'top',
    horizontal: 'right',
};
var UserMenu = function (props) {
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var translate = useTranslate();
    var _b = useGetIdentity(), loaded = _b.loaded, identity = _b.identity;
    var classes = useStyles(props);
    var children = props.children, _c = props.label, label = _c === void 0 ? 'ra.auth.user_menu' : _c, _d = props.icon, icon = _d === void 0 ? defaultIcon : _d, logout = props.logout;
    if (!logout && !children)
        return null;
    var open = Boolean(anchorEl);
    var handleMenu = function (event) { return setAnchorEl(event.currentTarget); };
    var handleClose = function () { return setAnchorEl(null); };
    return (React.createElement("div", { className: classes.user },
        loaded && (identity === null || identity === void 0 ? void 0 : identity.fullName) ? (React.createElement(Button, { "aria-label": label && translate(label, { _: label }), className: classes.userButton, color: "inherit", startIcon: identity.avatar ? (React.createElement(Avatar, { className: classes.avatar, src: identity.avatar, alt: identity.fullName })) : (icon), onClick: handleMenu }, identity.fullName)) : (React.createElement(Tooltip, { title: label && translate(label, { _: label }) },
            React.createElement(IconButton, { "aria-label": label && translate(label, { _: label }), "aria-owns": open ? 'menu-appbar' : null, "aria-haspopup": true, color: "inherit", onClick: handleMenu }, icon))),
        React.createElement(Menu, { id: "menu-appbar", disableScrollLock: true, anchorEl: anchorEl, anchorOrigin: AnchorOrigin, transformOrigin: TransformOrigin, 
            // Make sure the menu is display under the button and not over the appbar
            // See https://material-ui.com/components/menus/#customized-menus
            getContentAnchorEl: null, open: open, onClose: handleClose },
            Children.map(children, function (menuItem) {
                return isValidElement(menuItem)
                    ? cloneElement(menuItem, {
                        onClick: handleClose,
                    })
                    : null;
            }),
            logout)));
};
UserMenu.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    label: PropTypes.string,
    logout: PropTypes.element,
    icon: PropTypes.node,
};
export default UserMenu;
