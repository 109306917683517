var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Children, cloneElement, isValidElement, useRef, } from 'react';
import { Button, FormHelperText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/RemoveCircleOutline';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslate, ValidationError } from 'ra-core';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { IconButtonWithTooltip } from '../button';
import FormInput from './FormInput';
export var SimpleFormIterator = function (props) {
    var _a = props.addButton, addButton = _a === void 0 ? React.createElement(DefaultAddButton, null) : _a, _b = props.removeButton, removeButton = _b === void 0 ? React.createElement(DefaultRemoveButton, null) : _b, _c = props.reOrderButtons, reOrderButtons = _c === void 0 ? React.createElement(DefaultReOrderButtons, null) : _c, basePath = props.basePath, children = props.children, className = props.className, fields = props.fields, _d = props.meta, error = _d.error, submitFailed = _d.submitFailed, record = props.record, resource = props.resource, source = props.source, disabled = props.disabled, disableAdd = props.disableAdd, disableRemove = props.disableRemove, disableReordering = props.disableReordering, variant = props.variant, margin = props.margin, TransitionProps = props.TransitionProps, defaultValue = props.defaultValue, _e = props.getItemLabel, getItemLabel = _e === void 0 ? DefaultLabelFn : _e;
    var classes = useStyles(props);
    var nodeRef = useRef(null);
    // We need a unique id for each field for a proper enter/exit animation
    // so we keep an internal map between the field position and an auto-increment id
    var nextId = useRef(fields && fields.length
        ? fields.length
        : defaultValue
            ? defaultValue.length
            : 0);
    // We check whether we have a defaultValue (which must be an array) before checking
    // the fields prop which will always be empty for a new record.
    // Without it, our ids wouldn't match the default value and we would get key warnings
    // on the CssTransition element inside our render method
    var ids = useRef(nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []);
    var removeField = function (index) { return function () {
        ids.current.splice(index, 1);
        fields === null || fields === void 0 ? void 0 : fields.remove(index);
    }; };
    // Returns a boolean to indicate whether to disable the remove button for certain fields.
    // If disableRemove is a function, then call the function with the current record to
    // determining if the button should be disabled. Otherwise, use a boolean property that
    // enables or disables the button for all of the fields.
    var disableRemoveField = function (record, disableRemove) {
        if (typeof disableRemove === 'boolean') {
            return disableRemove;
        }
        return disableRemove && disableRemove(record);
    };
    var addField = function () {
        ids.current.push(nextId.current++);
        fields === null || fields === void 0 ? void 0 : fields.push(undefined);
    };
    // add field and call the onClick event of the button passed as addButton prop
    var handleAddButtonClick = function (originalOnClickHandler) { return function (event) {
        addField();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    }; };
    // remove field and call the onClick event of the button passed as removeButton prop
    var handleRemoveButtonClick = function (originalOnClickHandler, index) { return function (event) {
        removeField(index)();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    }; };
    var handleReorder = function (origin, destination) {
        var item = ids.current[origin];
        ids.current[origin] = ids.current[destination];
        ids.current[destination] = item;
        fields === null || fields === void 0 ? void 0 : fields.move(origin, destination);
    };
    var records = get(record, source);
    return fields ? (React.createElement("ul", { className: classNames(classes.root, className) },
        submitFailed && typeof error !== 'object' && error && (React.createElement(FormHelperText, { error: true },
            React.createElement(ValidationError, { error: error }))),
        React.createElement(TransitionGroup, { component: null }, fields.map(function (member, index) { return (React.createElement(CSSTransition, __assign({ nodeRef: nodeRef, key: ids.current[index], timeout: 500, classNames: "fade" }, TransitionProps),
            React.createElement("li", { className: classes.line },
                React.createElement("div", null,
                    React.createElement("div", { className: classes.indexContainer },
                        React.createElement(Typography, { variant: "body1", className: classes.index }, getItemLabel(index)),
                        !disabled &&
                            !disableReordering &&
                            cloneElement(reOrderButtons, {
                                index: index,
                                max: fields.length,
                                onReorder: handleReorder,
                                className: classNames('button-reorder', "button-reorder-" + source + "-" + index),
                            }))),
                React.createElement("section", { className: classes.form }, Children.map(children, function (input, index2) {
                    if (!isValidElement(input)) {
                        return null;
                    }
                    var _a = input.props, source = _a.source, inputProps = __rest(_a, ["source"]);
                    return (React.createElement(FormInput, { basePath: input.props.basePath ||
                            basePath, input: cloneElement(input, __assign({ source: source
                                ? member + "." + source
                                : member, index: source
                                ? undefined
                                : index2, label: typeof input.props
                                .label ===
                                'undefined'
                                ? source
                                    ? "resources." + resource + ".fields." + source
                                    : undefined
                                : input.props.label, disabled: disabled }, inputProps)), record: (records &&
                            records[index]) ||
                            {}, resource: resource, variant: variant, margin: margin }));
                })),
                !disabled &&
                    !disableRemoveField((records && records[index]) || {}, disableRemove) && (React.createElement("span", { className: classes.action }, cloneElement(removeButton, {
                    onClick: handleRemoveButtonClick(removeButton.props.onClick, index),
                    className: classNames('button-remove', "button-remove-" + source + "-" + index),
                })))))); })),
        !disabled && !disableAdd && (React.createElement("li", { className: classes.line },
            React.createElement("span", { className: classes.action }, cloneElement(addButton, {
                onClick: handleAddButtonClick(addButton.props.onClick),
                className: classNames('button-add', "button-add-" + source),
            })))))) : null;
};
SimpleFormIterator.defaultProps = {
    disableAdd: false,
    disableRemove: false,
};
SimpleFormIterator.propTypes = {
    defaultValue: PropTypes.any,
    addButton: PropTypes.element,
    removeButton: PropTypes.element,
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    // @ts-ignore
    fields: PropTypes.object,
    meta: PropTypes.object,
    // @ts-ignore
    record: PropTypes.object,
    source: PropTypes.string,
    resource: PropTypes.string,
    translate: PropTypes.func,
    disableAdd: PropTypes.bool,
    disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    TransitionProps: PropTypes.shape({}),
};
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        root: {
            padding: 0,
            marginBottom: 0,
            '& > li:last-child': {
                borderBottom: 'none',
            },
        },
        line: (_a = {
                display: 'flex',
                listStyleType: 'none',
                borderBottom: "solid 1px " + theme.palette.divider
            },
            _a[theme.breakpoints.down('xs')] = { display: 'block' },
            _a['&.fade-enter'] = {
                opacity: 0.01,
                transform: 'translateX(100vw)',
            },
            _a['&.fade-enter-active'] = {
                opacity: 1,
                transform: 'translateX(0)',
                transition: 'all 500ms ease-in',
            },
            _a['&.fade-exit'] = {
                opacity: 1,
                transform: 'translateX(0)',
            },
            _a['&.fade-exit-active'] = {
                opacity: 0.01,
                transform: 'translateX(100vw)',
                transition: 'all 500ms ease-in',
            },
            _a),
        index: (_b = {},
            _b[theme.breakpoints.down('sm')] = { display: 'none' },
            _b.marginRight = theme.spacing(1),
            _b),
        indexContainer: {
            display: 'flex',
            paddingTop: '1em',
            marginRight: theme.spacing(1),
            alignItems: 'center',
        },
        form: { flex: 2 },
        action: {
            paddingTop: '0.5em',
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
    });
}, { name: 'RaSimpleFormIterator' });
var DefaultAddButton = function (props) {
    var classes = useStyles(props);
    var translate = useTranslate();
    return (React.createElement(Button, __assign({ size: "small" }, props),
        React.createElement(AddIcon, { className: classes.leftIcon }),
        translate('ra.action.add')));
};
var DefaultLabelFn = function (index) { return index + 1; };
var DefaultRemoveButton = function (props) {
    var classes = useStyles(props);
    var translate = useTranslate();
    return (React.createElement(Button, __assign({ size: "small" }, props),
        React.createElement(CloseIcon, { className: classes.leftIcon }),
        translate('ra.action.remove')));
};
var DefaultReOrderButtons = function (_a) {
    var className = _a.className, index = _a.index, max = _a.max, onReorder = _a.onReorder;
    return (React.createElement("div", { className: className },
        React.createElement(IconButtonWithTooltip, { label: "ra.action.move_up", size: "small", onClick: function () { return onReorder(index, index - 1); }, disabled: index <= 0 },
            React.createElement(ArrowUpwardIcon, null)),
        React.createElement(IconButtonWithTooltip, { label: "ra.action.move_down", size: "small", onClick: function () { return onReorder(index, index + 1); }, disabled: max == null || index >= max - 1 },
            React.createElement(ArrowDownwardIcon, null))));
};
