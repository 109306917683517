var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Tab from '@material-ui/core/Tab';
import { useFormGroup, useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';
import { capitalize } from 'inflection';
/**
 * Single tab that selects a locale in a TranslatableInputs component.
 * @see TranslatableInputs
 */
export var TranslatableInputsTab = function (props) {
    var _a = props.groupKey, groupKey = _a === void 0 ? '' : _a, locale = props.locale, classesOverride = props.classes, rest = __rest(props, ["groupKey", "locale", "classes"]);
    var _b = useFormGroup("" + groupKey + locale), invalid = _b.invalid, touched = _b.touched;
    var classes = useStyles(props);
    var translate = useTranslate();
    return (React.createElement(Tab, __assign({ id: "translatable-header-" + groupKey + locale, label: translate("ra.locales." + locale, {
            _: capitalize(locale),
        }), className: classes.root + " " + (invalid && touched ? classes.error : '') }, rest)));
};
var useStyles = makeStyles(function (theme) { return ({
    root: {
        fontSize: '0.8em',
        minHeight: theme.spacing(3),
        minWidth: theme.spacing(6),
    },
    error: { color: theme.palette.error.main },
}); }, { name: 'RaTranslatableInputsTab' });
