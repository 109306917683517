var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Children, cloneElement, isValidElement, useState, } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Route, useRouteMatch, useLocation } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { escapePath, } from 'ra-core';
import Toolbar from './Toolbar';
import TabbedFormTabs, { getTabFullPath } from './TabbedFormTabs';
export var TabbedFormView = function (props) {
    var basePath = props.basePath, children = props.children, className = props.className, classesOverride = props.classes, handleSubmit = props.handleSubmit, handleSubmitWithRedirect = props.handleSubmitWithRedirect, invalid = props.invalid, mutationMode = props.mutationMode, pristine = props.pristine, record = props.record, defaultRedirect = props.redirect, resource = props.resource, saving = props.saving, submitOnEnter = props.submitOnEnter, _a = props.syncWithLocation, syncWithLocation = _a === void 0 ? true : _a, tabs = props.tabs, toolbar = props.toolbar, undoable = props.undoable, variant = props.variant, margin = props.margin, validating = props.validating, rest = __rest(props, ["basePath", "children", "className", "classes", "handleSubmit", "handleSubmitWithRedirect", "invalid", "mutationMode", "pristine", "record", "redirect", "resource", "saving", "submitOnEnter", "syncWithLocation", "tabs", "toolbar", "undoable", "variant", "margin", "validating"]);
    var classes = useTabbedFormViewStyles(props);
    var match = useRouteMatch();
    var location = useLocation();
    var url = match ? match.url : location.pathname;
    var _b = useState(0), tabValue = _b[0], setTabValue = _b[1];
    var handleTabChange = function (event, value) {
        if (!syncWithLocation) {
            setTabValue(value);
        }
    };
    return (React.createElement("form", __assign({ className: classnames('tabbed-form', className) }, sanitizeRestProps(rest)),
        cloneElement(tabs, {
            classes: classes,
            url: url,
            syncWithLocation: syncWithLocation,
            onChange: handleTabChange,
            value: tabValue,
        }, children),
        React.createElement(Divider, null),
        React.createElement("div", { className: classes.content }, Children.map(children, function (tab, index) {
            if (!tab) {
                return;
            }
            var tabPath = getTabFullPath(tab, index, url);
            return (React.createElement(Route, { exact: true, path: escapePath(tabPath) }, function (routeProps) {
                return isValidElement(tab)
                    ? React.cloneElement(tab, {
                        intent: 'content',
                        classes: classes,
                        resource: resource,
                        record: record,
                        basePath: basePath,
                        hidden: syncWithLocation
                            ? !routeProps.match
                            : tabValue !== index,
                        variant: tab.props.variant || variant,
                        margin: tab.props.margin || margin,
                        value: syncWithLocation
                            ? tabPath
                            : index,
                    })
                    : null;
            }));
        })),
        toolbar &&
            React.cloneElement(toolbar, {
                basePath: basePath,
                className: 'toolbar',
                handleSubmitWithRedirect: handleSubmitWithRedirect,
                handleSubmit: handleSubmit,
                invalid: invalid,
                mutationMode: mutationMode,
                pristine: pristine,
                record: record,
                redirect: defaultRedirect,
                resource: resource,
                saving: saving,
                submitOnEnter: submitOnEnter,
                validating: validating,
                undoable: undoable,
            })));
};
export var useTabbedFormViewStyles = makeStyles(function (theme) { return ({
    errorTabButton: { color: theme.palette.error.main },
    content: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}); }, { name: 'RaTabbedForm' });
TabbedFormView.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    classes: PropTypes.object,
    defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    invalid: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    mutationMode: PropTypes.oneOf(['pessimistic', 'optimistic', 'undoable']),
    pristine: PropTypes.bool,
    // @ts-ignore
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string,
    save: PropTypes.func,
    saving: PropTypes.bool,
    submitOnEnter: PropTypes.bool,
    tabs: PropTypes.element.isRequired,
    toolbar: PropTypes.element,
    translate: PropTypes.func,
    undoable: PropTypes.bool,
    validate: PropTypes.func,
    value: PropTypes.number,
    version: PropTypes.number,
};
TabbedFormView.defaultProps = {
    submitOnEnter: true,
    tabs: React.createElement(TabbedFormTabs, null),
    toolbar: React.createElement(Toolbar, null),
};
var sanitizeRestProps = function (_a) {
    var active = _a.active, dirty = _a.dirty, dirtyFields = _a.dirtyFields, dirtyFieldsSinceLastSubmit = _a.dirtyFieldsSinceLastSubmit, dirtySinceLastSubmit = _a.dirtySinceLastSubmit, error = _a.error, errors = _a.errors, form = _a.form, hasSubmitErrors = _a.hasSubmitErrors, hasValidationErrors = _a.hasValidationErrors, initialValues = _a.initialValues, _b = _a.modified, modified = _b === void 0 ? null : _b, modifiedSinceLastSubmit = _a.modifiedSinceLastSubmit, _c = _a.save, save = _c === void 0 ? null : _c, submitError = _a.submitError, submitErrors = _a.submitErrors, submitFailed = _a.submitFailed, submitSucceeded = _a.submitSucceeded, submitting = _a.submitting, _d = _a.touched, touched = _d === void 0 ? null : _d, valid = _a.valid, values = _a.values, _e = _a.visited, visited = _e === void 0 ? null : _e, _f = _a.__versions, __versions = _f === void 0 ? null : _f, props = __rest(_a, ["active", "dirty", "dirtyFields", "dirtyFieldsSinceLastSubmit", "dirtySinceLastSubmit", "error", "errors", "form", "hasSubmitErrors", "hasValidationErrors", "initialValues", "modified", "modifiedSinceLastSubmit", "save", "submitError", "submitErrors", "submitFailed", "submitSucceeded", "submitting", "touched", "valid", "values", "visited", "__versions"]);
    return props;
};
