import * as React from 'react';
import { FormContext } from './FormContext';
/**
 * Provides utilities to Form children, allowing them to change the default save function or register inputs to a group.
 * @param props The component props
 * @param {ReactNode} props.children The form content
 * @param {FormContextValue} props.value The form context
 */
export var FormContextProvider = function (_a) {
    var children = _a.children, value = _a.value;
    return React.createElement(FormContext.Provider, { value: value }, children);
};
