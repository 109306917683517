var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Route } from 'react-router-dom';
/**
 * Custom route with no layout, to be used in the <Admin customRoutes> array.
 *
 * @example
 * // in src/customRoutes.js
 * import * as React from "react";
 * import { Route } from 'react-router-dom';
 * import { RouteWithoutLayout } from 'react-admin';
 * import Foo from './Foo';
 * import Register from './Register';
 *
 * export default [
 *     <Route exact path="/foo" component={Foo} />,
 *     <RouteWithoutLayout exact path="/register" component={Register} />,
 * ];
 *
 * // in src/App.js
 * import * as React from "react";
 * import { Admin } from 'react-admin';
 *
 * import customRoutes from './customRoutes';
 *
 * const App = () => (
 *     <Admin customRoutes={customRoutes} dataProvider={simpleRestProvider('http://path.to.my.api')}>
 *         ...
 *     </Admin>
 * );
 *
 * export default App;
 */
export var RouteWithoutLayout = function (_a) {
    var noLayout = _a.noLayout, props = __rest(_a, ["noLayout"]);
    return React.createElement(Route, __assign({}, props));
};
RouteWithoutLayout.defaultProps = {
    noLayout: true,
};
