import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useTimeout } from 'ra-core';
export var AutocompleteInputLoader = function (_a) {
    var _b = _a.timeout, timeout = _b === void 0 ? 1000 : _b;
    var oneSecondHasPassed = useTimeout(timeout);
    if (oneSecondHasPassed) {
        return React.createElement(CircularProgress, { size: 24 });
    }
    return null;
};
