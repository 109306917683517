var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import ContentCreate from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import { linkToRecord, useResourceContext } from 'ra-core';
import Button from './Button';
/**
 * Opens the Edit view of a given record:
 *
 * @example // basic usage
 * import { EditButton } from 'react-admin';
 *
 * const CommentEditButton = ({ record }) => (
 *     <EditButton basePath="/comments" label="Edit comment" record={record} />
 * );
 */
var EditButton = function (props) {
    var _a = props.basePath, basePath = _a === void 0 ? '' : _a, _b = props.icon, icon = _b === void 0 ? defaultIcon : _b, _c = props.label, label = _c === void 0 ? 'ra.action.edit' : _c, record = props.record, _d = props.scrollToTop, scrollToTop = _d === void 0 ? true : _d, rest = __rest(props, ["basePath", "icon", "label", "record", "scrollToTop"]);
    var resource = useResourceContext();
    return (React.createElement(Button, __assign({ component: Link, to: useMemo(function () { return ({
            pathname: record
                ? linkToRecord(basePath || "/" + resource, record.id)
                : '',
            state: { _scrollToTop: scrollToTop },
        }); }, [basePath, record, resource, scrollToTop]), label: label, onClick: stopPropagation }, rest), icon));
};
var defaultIcon = React.createElement(ContentCreate, null);
// useful to prevent click bubbling in a datagrid with rowClick
var stopPropagation = function (e) { return e.stopPropagation(); };
EditButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
    scrollToTop: PropTypes.bool,
};
export default EditButton;
