var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { cloneElement, createElement, isValidElement, useCallback, useRef, useEffect, useMemo, } from 'react';
import PropTypes from 'prop-types';
import { sanitizeListRestProps, useListContext, useVersion, } from 'ra-core';
import { Table } from '@material-ui/core';
import classnames from 'classnames';
import union from 'lodash/union';
import difference from 'lodash/difference';
import { DatagridHeader } from './DatagridHeader';
import DatagridLoading from './DatagridLoading';
import DatagridBody, { PureDatagridBody } from './DatagridBody';
import useDatagridStyles from './useDatagridStyles';
import DatagridContextProvider from './DatagridContextProvider';
/**
 * The Datagrid component renders a list of records as a table.
 * It is usually used as a child of the <List> and <ReferenceManyField> components.
 *
 * Props:
 *  - rowStyle
 *
 * @example Display all posts as a datagrid
 * const postRowStyle = (record, index) => ({
 *     backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
 * });
 * export const PostList = (props) => (
 *     <List {...props}>
 *         <Datagrid rowStyle={postRowStyle}>
 *             <TextField source="id" />
 *             <TextField source="title" />
 *             <TextField source="body" />
 *             <EditButton />
 *         </Datagrid>
 *     </List>
 * );
 *
 * @example Display all the comments of the current post as a datagrid
 * <ReferenceManyField reference="comments" target="post_id">
 *     <Datagrid>
 *         <TextField source="id" />
 *         <TextField source="body" />
 *         <DateField source="created_at" />
 *         <EditButton />
 *     </Datagrid>
 * </ReferenceManyField>
 *
 *
 * @example Usage outside of a <List> or a <ReferenceManyField>.
 *
 * const currentSort = { field: 'published_at', order: 'DESC' };
 *
 * export const MyCustomList = (props) => {
 *     const { ids, data, total, loaded } = useGetList(
 *         'posts',
 *         { page: 1, perPage: 10 },
 *         currentSort
 *     );
 *
 *     return (
 *         <Datagrid
 *             basePath=""
 *             currentSort={currentSort}
 *             data={data}
 *             ids={ids}
 *             selectedIds={[]}
 *             loaded={loaded}
 *             total={total}
 *             setSort={() => {
 *                 console.log('set sort');
 *             }}
 *             onSelect={() => {
 *                 console.log('on select');
 *             }}
 *             onToggleItem={() => {
 *                 console.log('on toggle item');
 *             }}
 *         >
 *             <TextField source="id" />
 *             <TextField source="title" />
 *         </Datagrid>
 *     );
 * }
 */
var Datagrid = React.forwardRef(function (props, ref) {
    var classes = useDatagridStyles(props);
    var _a = props.optimized, optimized = _a === void 0 ? false : _a, _b = props.body, body = _b === void 0 ? optimized ? PureDatagridBody : DatagridBody : _b, _c = props.header, header = _c === void 0 ? DatagridHeader : _c, children = props.children, classesOverride = props.classes, className = props.className, empty = props.empty, expand = props.expand, _d = props.hasBulkActions, hasBulkActions = _d === void 0 ? false : _d, hover = props.hover, isRowSelectable = props.isRowSelectable, isRowExpandable = props.isRowExpandable, resource = props.resource, rowClick = props.rowClick, rowStyle = props.rowStyle, _e = props.size, size = _e === void 0 ? 'small' : _e, rest = __rest(props, ["optimized", "body", "header", "children", "classes", "className", "empty", "expand", "hasBulkActions", "hover", "isRowSelectable", "isRowExpandable", "resource", "rowClick", "rowStyle", "size"]);
    var _f = useListContext(props), basePath = _f.basePath, currentSort = _f.currentSort, data = _f.data, ids = _f.ids, loaded = _f.loaded, onSelect = _f.onSelect, onToggleItem = _f.onToggleItem, selectedIds = _f.selectedIds, setSort = _f.setSort, total = _f.total;
    var version = useVersion();
    var contextValue = useMemo(function () { return ({ isRowExpandable: isRowExpandable }); }, [
        isRowExpandable,
    ]);
    var lastSelected = useRef(null);
    useEffect(function () {
        if (!selectedIds || selectedIds.length === 0) {
            lastSelected.current = null;
        }
    }, [JSON.stringify(selectedIds)]); // eslint-disable-line react-hooks/exhaustive-deps
    var handleToggleItem = useCallback(function (id, event) {
        var lastSelectedIndex = ids.indexOf(lastSelected.current);
        lastSelected.current = event.target.checked ? id : null;
        if (event.shiftKey && lastSelectedIndex !== -1) {
            var index = ids.indexOf(id);
            var idsBetweenSelections = ids.slice(Math.min(lastSelectedIndex, index), Math.max(lastSelectedIndex, index) + 1);
            var newSelectedIds = event.target.checked
                ? union(selectedIds, idsBetweenSelections)
                : difference(selectedIds, idsBetweenSelections);
            onSelect(isRowSelectable
                ? newSelectedIds.filter(function (id) {
                    return isRowSelectable(data[id]);
                })
                : newSelectedIds);
        }
        else {
            onToggleItem(id);
        }
    }, [data, ids, isRowSelectable, onSelect, onToggleItem, selectedIds]);
    /**
     * if loaded is false, the list displays for the first time, and the dataProvider hasn't answered yet
     * if loaded is true, the data for the list has at least been returned once by the dataProvider
     * if loaded is undefined, the Datagrid parent doesn't track loading state (e.g. ReferenceArrayField)
     */
    if (loaded === false) {
        return (React.createElement(DatagridLoading, { classes: classes, className: className, expand: expand, hasBulkActions: hasBulkActions, nbChildren: React.Children.count(children), size: size }));
    }
    /**
     * Once loaded, the data for the list may be empty. Instead of
     * displaying the table header with zero data rows,
     * the datagrid displays nothing or a custom empty component.
     */
    if (loaded && (ids.length === 0 || total === 0)) {
        if (empty) {
            return empty;
        }
        return null;
    }
    /**
     * After the initial load, if the data for the list isn't empty,
     * and even if the data is refreshing (e.g. after a filter change),
     * the datagrid displays the current data.
     */
    return (React.createElement(DatagridContextProvider, { value: contextValue },
        React.createElement(Table, __assign({ ref: ref, className: classnames(classes.table, className), size: size }, sanitizeListRestProps(rest)),
            createOrCloneElement(header, {
                children: children,
                classes: classes,
                className: className,
                currentSort: currentSort,
                data: data,
                hasExpand: !!expand,
                hasBulkActions: hasBulkActions,
                ids: ids,
                isRowSelectable: isRowSelectable,
                onSelect: onSelect,
                resource: resource,
                selectedIds: selectedIds,
                setSort: setSort,
            }, children),
            createOrCloneElement(body, {
                basePath: basePath,
                className: classes.tbody,
                classes: classes,
                expand: expand,
                rowClick: rowClick,
                data: data,
                hasBulkActions: hasBulkActions,
                hover: hover,
                ids: ids,
                onToggleItem: handleToggleItem,
                resource: resource,
                rowStyle: rowStyle,
                selectedIds: selectedIds,
                isRowSelectable: isRowSelectable,
                version: version,
            }, children))));
});
var createOrCloneElement = function (element, props, children) {
    return isValidElement(element)
        ? cloneElement(element, props, children)
        : createElement(element, props, children);
};
Datagrid.propTypes = {
    basePath: PropTypes.string,
    // @ts-ignore
    body: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    children: PropTypes.node.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    currentSort: PropTypes.exact({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    data: PropTypes.any,
    empty: PropTypes.element,
    // @ts-ignore
    expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    hasBulkActions: PropTypes.bool,
    // @ts-ignore
    header: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    hover: PropTypes.bool,
    ids: PropTypes.arrayOf(PropTypes.any),
    loading: PropTypes.bool,
    onSelect: PropTypes.func,
    onToggleItem: PropTypes.func,
    resource: PropTypes.string,
    rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    rowStyle: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    setSort: PropTypes.func,
    total: PropTypes.number,
    version: PropTypes.number,
    isRowSelectable: PropTypes.func,
    isRowExpandable: PropTypes.func,
};
Datagrid.displayName = 'Datagrid';
export default Datagrid;
