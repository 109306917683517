import { useCallback } from 'react';
import useMutation from './useMutation';
/**
 * Get a callback to call the dataProvider.update() method, the result and the loading state.
 *
 * The return value updates according to the request state:
 *
 * - initial: [update, { loading: false, loaded: false }]
 * - start:   [update, { loading: true, loaded: false }]
 * - success: [update, { data: [data from response], loading: false, loaded: true }]
 * - error:   [update, { error: [error from response], loading: false, loaded: false }]
 *
 * @param resource The resource name, e.g. 'posts'
 * @param id The resource identifier, e.g. 123
 * @param data The updates to merge into the record, e.g. { views: 10 }
 * @param previousData The record before the update is applied
 * @param options Options object to pass to the dataProvider. May include side effects to be executed upon success or failure, e.g. { onSuccess: { refresh: true } }
 *
 * @returns The current request state. Destructure as [update, { data, error, loading, loaded }].
 *
 * The update() function can be called in 3 different ways:
 *  - with the same parameters as the useUpdate() hook: update(resource, id, data, previousData, options)
 *  - with the same syntax as useMutation: update({ resource, payload: { id, data, previousData } }, options)
 *  - with no parameter (if they were already passed to useUpdate()): update()
 *
 * @example // set params when calling the update callback
 *
 * import { useUpdate } from 'react-admin';
 *
 * const IncreaseLikeButton = ({ record }) => {
 *     const diff = { likes: record.likes + 1 };
 *     const [update, { loading, error }] = useUpdate();
 *     const handleClick = () => {
 *         update('likes', record.id, diff, record)
 *     }
 *     if (error) { return <p>ERROR</p>; }
 *     return <button disabled={loading} onClick={handleClick}>Like</div>;
 * };
 *
 * @example // set params when calling the hook
 *
 * import { useUpdate } from 'react-admin';
 *
 * const IncreaseLikeButton = ({ record }) => {
 *     const diff = { likes: record.likes + 1 };
 *     const [update, { loading, error }] = useUpdate('likes', record.id, diff, record);
 *     if (error) { return <p>ERROR</p>; }
 *     return <button disabled={loading} onClick={update}>Like</button>;
 * };
 *
 * @example // TypeScript
 * const [update, { data }] = useUpdate<Product>('products', id, changes, product);
 *                    \-- data is Product
 */
var useUpdate = function (resource, id, data, previousData, options) {
    var _a = useMutation({ type: 'update', resource: resource, payload: { id: id, data: data, previousData: previousData } }, options), mutate = _a[0], state = _a[1];
    var update = useCallback(function (resource, id, data, previousData, options) {
        if (typeof resource === 'string') {
            var query = {
                type: 'update',
                resource: resource,
                payload: {
                    id: id,
                    data: data,
                    previousData: previousData,
                },
            };
            return mutate(query, options);
        }
        else {
            return mutate(resource, id);
        }
    }, [mutate] // eslint-disable-line react-hooks/exhaustive-deps
    );
    return [update, state];
};
export default useUpdate;
