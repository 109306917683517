import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
/**
 * Scroll the window to top when the target location contains the _scrollToTop state
 *
 * @see CoreAdminRouter where it's enabled by default
 *
 * @example // usage in buttons
 * import { Link } from 'react-router-dom';
 * import { Button } from '@material-ui/core';
 *
 * const FooButton = () => (
 *     <Button
 *         component={Link}
 *         to={{
 *             pathname: '/foo',
 *             state: { _scrollToTop: true },
 *         }}
 *     >
 *         Go to foo
 *     </Button>
 * );
 */
export var useScrollToTop = function () {
    var history = useHistory();
    useEffect(function () {
        return history.listen(function (location, action) {
            var _a;
            if (action !== 'POP' && ((_a = location.state) === null || _a === void 0 ? void 0 : _a._scrollToTop) &&
                typeof window != 'undefined') {
                window.scrollTo(0, 0);
            }
        });
    }, [history]);
};
