import { useEffect, useRef } from 'react';
import { useForm } from 'react-final-form';
/**
 * Reset the submission error when the corresponding field changes.
 * final-form doesn't do this by default.
 */
var useResetSubmitErrors = function () {
    var form = useForm();
    var prevValues = useRef(form.getState().values);
    useEffect(function () {
        var unsubscribe = form.subscribe(function (_a) {
            var values = _a.values;
            form.mutators.resetSubmitErrors({
                current: values,
                prev: prevValues.current,
            });
            prevValues.current = values;
        }, { values: true });
        return unsubscribe;
    }, [form]);
};
export default useResetSubmitErrors;
