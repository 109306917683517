import { useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setListSelectedIds, toggleListItem } from '../actions/listActions';
var defaultRecords = [];
/**
 * Get the list of selected items for a resource, and callbacks to change the selection
 *
 * @param resource The resource name, e.g. 'posts'
 *
 * @returns {Object} Destructure as [selectedIds, { select, toggle, clearSelection }].
 */
var useRecordSelection = function (resource) {
    var dispatch = useDispatch();
    var selectedIds = useSelector(function (reduxState) {
        return reduxState.admin.resources[resource]
            ? reduxState.admin.resources[resource].list.selectedIds
            : defaultRecords;
    }, shallowEqual);
    var selectionModifiers = useMemo(function () { return ({
        select: function (newIds) {
            dispatch(setListSelectedIds(resource, newIds));
        },
        toggle: function (id) {
            dispatch(toggleListItem(resource, id));
        },
        clearSelection: function () {
            dispatch(setListSelectedIds(resource, []));
        },
    }); }, [dispatch, resource]);
    return [selectedIds, selectionModifiers];
};
export default useRecordSelection;
