var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getResultFromCache } from '../replyWithCache';
import getFetchType from '../getFetchType';
import { FETCH_END } from '../../actions/fetchActions';
export var answerWithCache = function (_a) {
    var type = _a.type, payload = _a.payload, resource = _a.resource, action = _a.action, rest = _a.rest, onSuccess = _a.onSuccess, resourceState = _a.resourceState, dispatch = _a.dispatch;
    dispatch({
        type: action,
        payload: payload,
        meta: __assign({ resource: resource }, rest),
    });
    var response = getResultFromCache(type, payload, resourceState);
    dispatch({
        type: action + "_SUCCESS",
        payload: response,
        requestPayload: payload,
        meta: __assign(__assign({}, rest), { resource: resource, fetchResponse: getFetchType(type), fetchStatus: FETCH_END, fromCache: true }),
    });
    onSuccess && onSuccess(response);
    return Promise.resolve(response);
};
