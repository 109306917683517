import * as React from 'react';
import { memo } from 'react';
import useTranslate from '../i18n/useTranslate';
import getFieldLabelTranslationArgs from './getFieldLabelTranslationArgs';
export var FieldTitle = function (props) {
    var resource = props.resource, source = props.source, label = props.label, isRequired = props.isRequired;
    var translate = useTranslate();
    if (label === false || label === '') {
        return null;
    }
    if (label && typeof label !== 'string') {
        return label;
    }
    return (React.createElement("span", null,
        translate.apply(void 0, getFieldLabelTranslationArgs({
            label: label,
            resource: resource,
            source: source,
        })),
        isRequired && ' *'));
};
// What? TypeScript loses the displayName if we don't set it explicitly
FieldTitle.displayName = 'FieldTitle';
export default memo(FieldTitle);
