import get from 'lodash/get';
import { linkToRecord } from '../../util';
/**
 * Get the link toward the referenced resource
 *
 * @example
 *
 * const linkPath = getResourceLinkPath({
 *      basePath: '/comments',
 *      link: 'edit',
 *      reference: 'users',
 *      record: {
 *          userId: 7
 *      },
 *      resource: 'comments',
 *      source: 'userId',
 * }); // '/users/7'
 *
 * @param {Object} option
 * @param {string} option.basePath basepath to current resource
 * @param {string | false | LinkToFunctionType} option.link="edit" The link toward the referenced record. 'edit', 'show' or false for no link (default to edit). Alternatively a function that returns a string
 * @param {string | false | LinkToFunctionType} [option.linkType] DEPRECATED : old name for link
 * @param {string} option.reference The linked resource name
 * @param {Object} option.record The current resource record
 * @param {string} option.resource The current resource name
 * @param {string} option.source The key of the linked resource identifier
 *
 * @returns {string | false} The link to the reference record
 */
var getResourceLinkPath = function (_a) {
    var resource = _a.resource, source = _a.source, reference = _a.reference, _b = _a.link, link = _b === void 0 ? 'edit' : _b, _c = _a.record, record = _c === void 0 ? { id: '' } : _c, _d = _a.basePath, basePath = _d === void 0 ? '' : _d, linkType = _a.linkType;
    if (linkType !== undefined) {
        console.warn("The 'linkType' prop is deprecated and should be named to 'link' in <ReferenceField />");
    }
    var sourceId = get(record, source);
    var rootPath = basePath
        ? basePath.replace(resource, reference)
        : "/" + reference;
    var linkTo = linkType !== undefined ? linkType : link;
    // Backward compatibility: keep linkType but with warning
    return !linkTo
        ? false
        : typeof linkTo === 'function'
            ? linkTo(record, reference)
            : linkToRecord(rootPath, sourceId, linkTo);
};
export default getResourceLinkPath;
