var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import MuiTab from '@material-ui/core/Tab';
import classnames from 'classnames';
import { useTranslate, useFormGroup } from 'ra-core';
import { useFormState } from 'react-final-form';
export var FormTabHeader = function (_a) {
    var _b;
    var classes = _a.classes, label = _a.label, value = _a.value, icon = _a.icon, className = _a.className, syncWithLocation = _a.syncWithLocation, rest = __rest(_a, ["classes", "label", "value", "icon", "className", "syncWithLocation"]);
    var translate = useTranslate();
    var location = useLocation();
    var submitFailed = useFormState(UseFormStateOptions).submitFailed;
    var formGroup = useFormGroup(value.toString());
    var propsForLink = {
        component: Link,
        to: __assign(__assign({}, location), { pathname: value }),
    };
    return (React.createElement(MuiTab, __assign({ label: isValidElement(label) ? label : translate(label, { _: label }), value: value, icon: icon, className: classnames('form-tab', className, (_b = {},
            _b[classes.errorTabButton] = formGroup.invalid && (formGroup.touched || submitFailed),
            _b)) }, (syncWithLocation ? propsForLink : {}), { id: "tabheader-" + value, "aria-controls": "tabpanel-" + value }, rest)));
};
var UseFormStateOptions = {
    subscription: {
        submitFailed: true,
    },
};
FormTabHeader.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    children: PropTypes.node,
    intent: PropTypes.oneOf(['header', 'content']),
    hidden: PropTypes.bool,
    icon: PropTypes.element,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    path: PropTypes.string,
    // @ts-ignore
    record: PropTypes.object,
    resource: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
};
