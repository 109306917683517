var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import PropTypes from 'prop-types';
import { FormGroupContextProvider } from 'ra-core';
import FormInput from './FormInput';
import { FormTabHeader } from './FormTabHeader';
var hiddenStyle = { display: 'none' };
export var FormTab = function (props) {
    var basePath = props.basePath, className = props.className, classes = props.classes, contentClassName = props.contentClassName, children = props.children, hidden = props.hidden, icon = props.icon, intent = props.intent, label = props.label, margin = props.margin, path = props.path, record = props.record, resource = props.resource, variant = props.variant, value = props.value, rest = __rest(props, ["basePath", "className", "classes", "contentClassName", "children", "hidden", "icon", "intent", "label", "margin", "path", "record", "resource", "variant", "value"]);
    var renderHeader = function () { return (React.createElement(FormTabHeader, __assign({ label: label, value: value, icon: icon, className: className, classes: classes }, rest))); };
    var renderContent = function () { return (React.createElement(FormGroupContextProvider, { name: value.toString() },
        React.createElement("span", { style: hidden ? hiddenStyle : null, className: contentClassName, id: "tabpanel-" + value, "aria-labelledby": "tabheader-" + value, "aria-hidden": hidden || undefined }, React.Children.map(children, function (input) {
            return input && (React.createElement(FormInput, { basePath: basePath, input: input, record: record, resource: resource, variant: input.props.variant || variant, margin: input.props.margin || margin }));
        })))); };
    return intent === 'header' ? renderHeader() : renderContent();
};
FormTab.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    children: PropTypes.node,
    intent: PropTypes.oneOf(['header', 'content']),
    hidden: PropTypes.bool,
    icon: PropTypes.element,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    path: PropTypes.string,
    // @ts-ignore
    record: PropTypes.object,
    resource: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
};
FormTab.displayName = 'FormTab';
