var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, cloneElement, useCallback, } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSidebarVisibility } from 'ra-core';
import { MenuItem, ListItemIcon, Tooltip, useMediaQuery, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
var NavLinkRef = forwardRef(function (props, ref) { return (React.createElement(NavLink, __assign({ innerRef: ref }, props))); });
var useStyles = makeStyles(function (theme) { return ({
    root: {
        color: theme.palette.text.secondary,
    },
    active: {
        color: theme.palette.text.primary,
    },
    icon: { minWidth: theme.spacing(5) },
}); }, { name: 'RaMenuItemLink' });
/**
 * Displays a menu item with a label and an icon - or only the icon with a tooltip when the sidebar is minimized.
 * It also handles the automatic closing of the menu on tap on mobile.
 *
 * @typedef {Object} Props the props you can use
 * @prop {string|Location} to The menu item's target. It is passed to a React Router NavLink component.
 * @prop {string|ReactNode} primaryText The menu content, displayed when the menu isn't minimized. |
 * @prop {ReactNode} leftIcon The menu icon
 *
 * Additional props are passed down to the underling material-ui <MenuItem> component
 * @see https://material-ui.com/api/menu-item/#menuitem-api
 *
 * @example // You can create a custom menu component using the <DashboardMenuItem> and <MenuItemLink> components:
 *
 * // in src/Menu.js
 * import * as React from 'react';
 * import { DashboardMenuItem, MenuItemLink } from 'react-admin';
 * import BookIcon from '@material-ui/icons/Book';
 * import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
 * import PeopleIcon from '@material-ui/icons/People';
 * import LabelIcon from '@material-ui/icons/Label';
 *
 * export const Menu = () => (
 *     <div>
 *         <DashboardMenuItem />
 *         <MenuItemLink to="/posts" primaryText="Posts" leftIcon={<BookIcon />}/>
 *         <MenuItemLink to="/comments" primaryText="Comments" leftIcon={<ChatBubbleIcon />}/>
 *         <MenuItemLink to="/users" primaryText="Users" leftIcon={<PeopleIcon />}/>
 *         <MenuItemLink to="/custom-route" primaryText="Miscellaneous" leftIcon={<LabelIcon />}/>
 *     </div>
 * );
 *
 * // to use this custom menu component, pass it to a custom Layout:
 * // in src/Layout.js
 * import { Layout } from 'react-admin';
 * import { Menu } from './Menu';
 *
 * export const Layout = (props) => <Layout {...props} menu={Menu} />;
 *
 * // then, use this layout in the <Admin layout> prop:
 * // in src/App.js
 * import { Layout }  from './Layout';
 *
 * const App = () => (
 *     <Admin layout={Layout} dataProvider={simpleRestProvider('http://path.to.my.api')}>
 *         // ...
 *     </Admin>
 * );
 */
var MenuItemLink = forwardRef(function (props, ref) {
    var classesOverride = props.classes, className = props.className, primaryText = props.primaryText, leftIcon = props.leftIcon, onClick = props.onClick, sidebarIsOpen = props.sidebarIsOpen, tooltipProps = props.tooltipProps, rest = __rest(props, ["classes", "className", "primaryText", "leftIcon", "onClick", "sidebarIsOpen", "tooltipProps"]);
    var classes = useStyles(props);
    var dispatch = useDispatch();
    var isSmall = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var open = useSelector(function (state) { return state.admin.ui.sidebarOpen; });
    var handleMenuTap = useCallback(function (e) {
        if (isSmall) {
            dispatch(setSidebarVisibility(false));
        }
        onClick && onClick(e);
    }, [dispatch, isSmall, onClick]);
    var renderMenuItem = function () {
        return (React.createElement(MenuItem, __assign({ className: classnames(classes.root, className), activeClassName: classes.active, component: NavLinkRef, ref: ref, tabIndex: 0 }, rest, { onClick: handleMenuTap }),
            leftIcon && (React.createElement(ListItemIcon, { className: classes.icon }, cloneElement(leftIcon, {
                titleAccess: primaryText,
            }))),
            primaryText));
    };
    return open ? (renderMenuItem()) : (React.createElement(Tooltip, __assign({ title: primaryText, placement: "right" }, tooltipProps), renderMenuItem()));
});
MenuItemLink.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    leftIcon: PropTypes.element,
    onClick: PropTypes.func,
    primaryText: PropTypes.node,
    staticContext: PropTypes.object,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    sidebarIsOpen: PropTypes.bool,
};
export default MenuItemLink;
