import { useSelector } from 'react-redux';
import defaults from 'lodash/defaults';
import { getResources } from '../reducer';
import { useResourceContext } from './useResourceContext';
import { useMemo } from 'react';
/**
 * Hook which returns the definition of the requested resource
 */
export var useResourceDefinition = function (props) {
    var resource = useResourceContext(props);
    var resources = useSelector(getResources);
    var hasCreate = props.hasCreate, hasEdit = props.hasEdit, hasList = props.hasList, hasShow = props.hasShow;
    var definition = useMemo(function () {
        var definitionFromRedux = resources.find(function (r) { return (r === null || r === void 0 ? void 0 : r.name) === resource; });
        return defaults({}, {
            hasCreate: hasCreate,
            hasEdit: hasEdit,
            hasList: hasList,
            hasShow: hasShow,
        }, definitionFromRedux);
    }, [resource, resources, hasCreate, hasEdit, hasList, hasShow]);
    return definition;
};
