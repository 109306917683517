var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
// @ts-ignore
import { makeStyles } from '@material-ui/core/styles';
import DefaultIcon from '@material-ui/icons/ViewList';
import classnames from 'classnames';
import { useGetResourceLabel, getResources } from 'ra-core';
import DashboardMenuItem from './DashboardMenuItem';
import MenuItemLink from './MenuItemLink';
export var MENU_WIDTH = 240;
export var CLOSED_MENU_WIDTH = 55;
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        main: (_a = {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                marginTop: '0.5em',
                marginBottom: '1em'
            },
            _a[theme.breakpoints.only('xs')] = {
                marginTop: 0,
            },
            _a.transition = theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            _a),
        open: {
            width: lodashGet(theme, 'menu.width', MENU_WIDTH),
        },
        closed: {
            width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
        },
    });
}, { name: 'RaMenu' });
var Menu = function (props) {
    var _a;
    var resources = useSelector(getResources, shallowEqual);
    var getResourceLabel = useGetResourceLabel();
    var hasDashboard = props.hasDashboard, dense = props.dense, _b = props.children, children = _b === void 0 ? (React.createElement(React.Fragment, null,
        hasDashboard && React.createElement(DashboardMenuItem, { dense: dense }),
        resources
            .filter(function (r) { return r.hasList; })
            .map(function (resource) { return (React.createElement(MenuItemLink, { key: resource.name, to: {
                pathname: "/" + resource.name,
                state: { _scrollToTop: true },
            }, primaryText: getResourceLabel(resource.name, 2), leftIcon: resource.icon ? (React.createElement(resource.icon, null)) : (React.createElement(DefaultIcon, null)), dense: dense })); }))) : _b, classesOverride = props.classes, className = props.className, onMenuClick = props.onMenuClick, logout = props.logout, rest = __rest(props, ["hasDashboard", "dense", "children", "classes", "className", "onMenuClick", "logout"]);
    var classes = useStyles(props);
    var open = useSelector(function (state) { return state.admin.ui.sidebarOpen; });
    return (React.createElement("div", __assign({ className: classnames(classes.main, (_a = {},
            _a[classes.open] = open,
            _a[classes.closed] = !open,
            _a), className) }, rest), children));
};
Menu.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    dense: PropTypes.bool,
    hasDashboard: PropTypes.bool,
    logout: PropTypes.element,
    onMenuClick: PropTypes.func,
};
Menu.defaultProps = {
    onMenuClick: function () { return null; },
};
export default Menu;
