import * as React from 'react';
import { Children, isValidElement, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useListContext, useResourceContext, useTranslate, } from 'ra-core';
import { Checkbox, TableCell, TableHead, TableRow } from '@material-ui/core';
import classnames from 'classnames';
import DatagridHeaderCell from './DatagridHeaderCell';
/**
 * The default Datagrid Header component.
 *
 * Renders select all checkbox as well as column header buttons used for sorting.
 */
export var DatagridHeader = function (props) {
    var children = props.children, classes = props.classes, className = props.className, _a = props.hasExpand, hasExpand = _a === void 0 ? false : _a, _b = props.hasBulkActions, hasBulkActions = _b === void 0 ? false : _b, isRowSelectable = props.isRowSelectable;
    var resource = useResourceContext(props);
    var translate = useTranslate();
    var _c = useListContext(props), currentSort = _c.currentSort, data = _c.data, ids = _c.ids, onSelect = _c.onSelect, selectedIds = _c.selectedIds, setSort = _c.setSort;
    var updateSortCallback = useCallback(function (event) {
        event.stopPropagation();
        var newField = event.currentTarget.dataset.field;
        var newOrder = currentSort.field === newField
            ? currentSort.order === 'ASC'
                ? 'DESC'
                : 'ASC'
            : event.currentTarget.dataset.order;
        setSort(newField, newOrder);
    }, [currentSort.field, currentSort.order, setSort]);
    var updateSort = setSort ? updateSortCallback : null;
    var handleSelectAll = useCallback(function (event) {
        if (event.target.checked) {
            var all = ids.concat(selectedIds.filter(function (id) { return !ids.includes(id); }));
            onSelect(isRowSelectable
                ? all.filter(function (id) { return isRowSelectable(data[id]); })
                : all);
        }
        else {
            onSelect([]);
        }
    }, [data, ids, onSelect, isRowSelectable, selectedIds]);
    var selectableIds = isRowSelectable
        ? ids.filter(function (id) { return isRowSelectable(data[id]); })
        : ids;
    return (React.createElement(TableHead, { className: classnames(className, classes.thead) },
        React.createElement(TableRow, { className: classnames(classes.row, classes.headerRow) },
            hasExpand && (React.createElement(TableCell, { padding: "none", className: classnames(classes.headerCell, classes.expandHeader) })),
            hasBulkActions && selectedIds && (React.createElement(TableCell, { padding: "checkbox", className: classes.headerCell },
                React.createElement(Checkbox, { "aria-label": translate('ra.action.select_all', {
                        _: 'Select all',
                    }), className: "select-all", color: "primary", checked: selectedIds.length > 0 &&
                        selectableIds.length > 0 &&
                        selectableIds.every(function (id) {
                            return selectedIds.includes(id);
                        }), onChange: handleSelectAll }))),
            Children.map(children, function (field, index) {
                return isValidElement(field) ? (React.createElement(DatagridHeaderCell, { className: classes.headerCell, currentSort: currentSort, field: field, isSorting: currentSort.field ===
                        (field.props.sortBy ||
                            field.props.source), key: field.props.source || index, resource: resource, updateSort: updateSort })) : null;
            }))));
};
DatagridHeader.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    currentSort: PropTypes.exact({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    data: PropTypes.any,
    hasExpand: PropTypes.bool,
    hasBulkActions: PropTypes.bool,
    ids: PropTypes.arrayOf(PropTypes.any),
    isRowSelectable: PropTypes.func,
    isRowExpandable: PropTypes.func,
    onSelect: PropTypes.func,
    onToggleItem: PropTypes.func,
    resource: PropTypes.string,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    setSort: PropTypes.func,
};
DatagridHeader.displayName = 'DatagridHeader';
