var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { AppBar, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslatableContext } from 'ra-core';
import { TranslatableInputsTab } from './TranslatableInputsTab';
/**
 * Default locale selector for the TranslatableInputs component. Generates a tab for each specified locale.
 * @see TranslatableInputs
 */
export var TranslatableInputsTabs = function (props) {
    var groupKey = props.groupKey, tabsProps = props.TabsProps;
    var _a = useTranslatableContext(), locales = _a.locales, selectLocale = _a.selectLocale, selectedLocale = _a.selectedLocale;
    var classes = useStyles(props);
    var handleChange = function (event, newLocale) {
        selectLocale(newLocale);
    };
    return (React.createElement(AppBar, { color: "default", position: "static", className: classes.root },
        React.createElement(Tabs, __assign({ value: selectedLocale, onChange: handleChange, indicatorColor: "primary", textColor: "primary", className: classes.tabs }, tabsProps), locales.map(function (locale) { return (React.createElement(TranslatableInputsTab, { key: locale, value: locale, locale: locale, groupKey: groupKey })); }))));
};
var useStyles = makeStyles(function (theme) { return ({
    root: {
        boxShadow: 'none',
        borderRadius: 0,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        border: "1px solid " + theme.palette.divider,
    },
    tabs: {
        minHeight: theme.spacing(3),
    },
}); }, { name: 'RaTranslatableInputsTabs' });
