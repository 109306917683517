var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Labeled from '../input/Labeled';
var sanitizeRestProps = function (_a) {
    var basePath = _a.basePath, record = _a.record, rest = __rest(_a, ["basePath", "record"]);
    return rest;
};
var useStyles = makeStyles(function (theme) { return ({
    input: { width: theme.spacing(32) },
}); }, { name: 'RaFormInput' });
var FormInput = function (props) {
    var _a, _b;
    var input = props.input, classesOverride = props.classes, rest = __rest(props, ["input", "classes"]);
    var classes = useStyles(props);
    var _c = input
        ? input.props
        : { id: undefined, className: undefined }, id = _c.id, className = _c.className, inputProps = __rest(_c, ["id", "className"]);
    return input ? (React.createElement("div", { className: classnames('ra-input', "ra-input-" + input.props.source, input.props.formClassName) }, input.props.addLabel ? (React.createElement(Labeled, __assign({ id: id || input.props.source }, inputProps, sanitizeRestProps(rest)), React.cloneElement(input, __assign(__assign({ className: classnames((_a = {},
            _a[classes.input] = !input.props.fullWidth,
            _a), className), id: input.props.id || input.props.source }, rest), inputProps)))) : (React.cloneElement(input, __assign(__assign({ className: classnames((_b = {},
            _b[classes.input] = !input.props.fullWidth,
            _b), className), id: input.props.id || input.props.source }, rest), inputProps))))) : null;
};
FormInput.propTypes = {
    classes: PropTypes.object,
    // @ts-ignore
    input: PropTypes.node,
};
// What? TypeScript loses the displayName if we don't set it explicitly
FormInput.displayName = 'FormInput';
export default FormInput;
