var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import { useGetMany } from '../../dataProvider';
import { useForm } from 'react-final-form';
import useGetMatching from '../../dataProvider/useGetMatching';
import { useTranslate } from '../../i18n';
import { getStatusForArrayInput as getDataStatus } from './referenceDataStatus';
import { useResourceContext } from '../../core';
import { usePaginationState, useSortState } from '..';
import { indexById, removeEmpty, useSafeSetState } from '../../util';
/**
 * Prepare data for the ReferenceArrayInput components
 *
 * @example
 *
 * const { choices, error, loaded, loading } = useReferenceArrayInputController({
 *      basePath: 'resource';
 *      record: { referenceIds: ['id1', 'id2']};
 *      reference: 'reference';
 *      resource: 'resource';
 *      source: 'referenceIds';
 * });
 *
 * @param {Object} props
 * @param {string} props.basePath basepath to current resource
 * @param {Object} props.record The current resource record
 * @param {string} props.reference The linked resource name
 * @param {string} props.resource The current resource name
 * @param {string} props.source The key of the linked resource identifier
 *
 * @param {Props} props
 *
 * @return {Object} controllerProps Fetched data and callbacks for the ReferenceArrayInput components
 */
export var useReferenceArrayInputController = function (props) {
    var defaultFilter = props.filter, _a = props.filterToQuery, filterToQuery = _a === void 0 ? defaultFilterToQuery : _a, input = props.input, _b = props.page, initialPage = _b === void 0 ? 1 : _b, _c = props.perPage, initialPerPage = _c === void 0 ? 25 : _c, _d = props.sort, initialSort = _d === void 0 ? { field: 'id', order: 'DESC' } : _d, options = props.options, reference = props.reference, source = props.source;
    var resource = useResourceContext(props);
    var translate = useTranslate();
    // We store the current input value in a ref so that we are able to fetch
    // only the missing references when the input value changes
    var inputValue = useRef(input.value);
    var _e = useState(input.value), idsToFetch = _e[0], setIdsToFetch = _e[1];
    var _f = useState(EmptyArray), idsToGetFromStore = _f[0], setIdsToGetFromStore = _f[1];
    var referenceRecordsFromStore = useSelector(function (state) {
        return idsToGetFromStore.map(function (id) { return state.admin.resources[reference].data[id]; });
    });
    // optimization: we fetch selected items only once. When the user selects more items,
    // as we already have the past selected items in the store, we don't fetch them.
    useEffect(function () {
        // Only fetch new ids
        var newIdsToFetch = difference(input.value, inputValue.current);
        // Only get from store ids selected and already fetched
        var newIdsToGetFromStore = difference(input.value, newIdsToFetch);
        /*
            input.value (current)
                +------------------------+
                | ********************** |
                | ********************** |  inputValue.current (old)
                | ********** +-----------------------+
                | ********** | ooooooooo |           |
                | ********** | ooooooooo |           |
                | ********** | ooooooooo |           |
                | ********** | ooooooooo |           |
                +---|--------|------|----+           |
                    |        |      |                |
                    |        |      |                |
                    |        +------|----------------+
                    |               |
            newIdsToFetch    newIdsToGetFromStore
        */
        // Change states each time input values changes to avoid keeping previous values no more selected
        if (!isEqual(idsToFetch, newIdsToFetch)) {
            setIdsToFetch(newIdsToFetch);
        }
        if (!isEqual(idsToGetFromStore, newIdsToGetFromStore)) {
            setIdsToGetFromStore(newIdsToGetFromStore);
        }
        inputValue.current = input.value;
    }, [
        idsToFetch,
        idsToGetFromStore,
        input.value,
        setIdsToFetch,
        setIdsToGetFromStore,
    ]);
    // pagination logic
    var _g = usePaginationState({
        page: initialPage,
        perPage: initialPerPage,
    }), page = _g.page, setPage = _g.setPage, perPage = _g.perPage, setPerPage = _g.setPerPage, pagination = _g.pagination, setPagination = _g.setPagination;
    var form = useForm();
    var onSelect = useCallback(function (newIds) {
        // This could happen when user unselect all items using the datagrid for instance
        if (newIds.length === 0) {
            form.change(input.name, EmptyArray);
            return;
        }
        var newValue = new Set(input.value);
        newIds.forEach(function (newId) {
            newValue.add(newId);
        });
        form.change(input.name, Array.from(newValue));
    }, [form, input.value, input.name]);
    var onUnselectItems = useCallback(function () {
        form.change(input.name, EmptyArray);
    }, [form, input.name]);
    var onToggleItem = useCallback(function (id) {
        if (input.value.some(function (selectedId) { return selectedId === id; })) {
            form.change(input.name, input.value.filter(function (selectedId) { return selectedId !== id; }));
        }
        else {
            form.change(input.name, __spreadArrays(input.value, [id]));
        }
    }, [form, input.name, input.value]);
    // sort logic
    var sortRef = useRef(initialSort);
    var _h = useSortState(initialSort), sort = _h.sort, setSort = _h.setSort;
    // ReferenceArrayInput.setSort had a different signature than the one from ListContext.
    // In order to not break backward compatibility, we added this temporary setSortForList in the
    // ReferenceArrayInputContext
    var setSortForList = useCallback(function (field, order) {
        if (order === void 0) { order = 'ASC'; }
        setSort({ field: field, order: order });
        setPage(1);
    }, [setPage, setSort]);
    // Ensure sort can be updated through props too, not just by using the setSort function
    useEffect(function () {
        if (!isEqual(initialSort, sortRef.current)) {
            setSort(initialSort);
        }
    }, [setSort, initialSort]);
    // Ensure pagination can be updated through props too, not just by using the setPagination function
    var paginationRef = useRef({ initialPage: initialPage, initialPerPage: initialPerPage });
    useEffect(function () {
        if (!isEqual({ initialPage: initialPage, initialPerPage: initialPerPage }, paginationRef.current)) {
            setPagination({ page: initialPage, perPage: initialPerPage });
        }
    }, [setPagination, initialPage, initialPerPage]);
    // filter logic
    var _j = useState(''), queryFilter = _j[0], setFilter = _j[1];
    var filterRef = useRef(defaultFilter);
    var _k = useSafeSetState({}), displayedFilters = _k[0], setDisplayedFilters = _k[1];
    var _l = useSafeSetState(defaultFilter), filterValues = _l[0], setFilterValues = _l[1];
    var hideFilter = useCallback(function (filterName) {
        setDisplayedFilters(function (previousState) {
            var _a = previousState, _b = filterName, _ = _a[_b], newState = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
            return newState;
        });
        setFilterValues(function (previousState) {
            var _a = previousState, _b = filterName, _ = _a[_b], newState = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
            return newState;
        });
    }, [setDisplayedFilters, setFilterValues]);
    var showFilter = useCallback(function (filterName, defaultValue) {
        setDisplayedFilters(function (previousState) {
            var _a;
            return (__assign(__assign({}, previousState), (_a = {}, _a[filterName] = true, _a)));
        });
        setFilterValues(function (previousState) {
            var _a;
            return (__assign(__assign({}, previousState), (_a = {}, _a[filterName] = defaultValue, _a)));
        });
    }, [setDisplayedFilters, setFilterValues]);
    var setFilters = useCallback(function (filters, displayedFilters) {
        setFilterValues(removeEmpty(filters));
        setDisplayedFilters(displayedFilters);
        setPage(1);
    }, [setDisplayedFilters, setFilterValues, setPage]);
    // handle filter prop change
    useEffect(function () {
        if (!isEqual(defaultFilter, filterRef.current)) {
            filterRef.current = defaultFilter;
            setFilterValues(defaultFilter);
        }
    });
    // Merge the user filters with the default ones
    var finalFilter = useMemo(function () { return (__assign(__assign({}, defaultFilter), filterToQuery(queryFilter))); }, [queryFilter, defaultFilter, filterToQuery]);
    var _m = useGetMany(reference, idsToFetch || EmptyArray), referenceRecordsFetched = _m.data, loaded = _m.loaded, refetchGetMany = _m.refetch;
    var referenceRecords = referenceRecordsFetched
        ? referenceRecordsFetched.concat(referenceRecordsFromStore)
        : referenceRecordsFromStore;
    // filter out not found references - happens when the dataProvider doesn't guarantee referential integrity
    var finalReferenceRecords = referenceRecords.filter(Boolean);
    var _o = useGetMatching(reference, pagination, sort, finalFilter, source, resource, options), matchingReferences = _o.data, matchingReferencesIds = _o.ids, total = _o.total, refetchGetMatching = _o.refetch;
    // We merge the currently selected records with the matching ones, otherwise
    // the component displaying the currently selected records may fail
    var finalMatchingReferences = matchingReferences && matchingReferences.length > 0
        ? mergeReferences(matchingReferences, finalReferenceRecords)
        : finalReferenceRecords.length > 0
            ? finalReferenceRecords
            : matchingReferences;
    var dataStatus = getDataStatus({
        input: input,
        matchingReferences: finalMatchingReferences,
        referenceRecords: finalReferenceRecords,
        translate: translate,
    });
    var refetch = useCallback(function () {
        refetchGetMany();
        refetchGetMatching();
    }, [refetchGetMany, refetchGetMatching]);
    return {
        basePath: props.basePath || "/" + resource,
        choices: dataStatus.choices,
        currentSort: sort,
        // For the ListContext, we don't want to always display the selected items first.
        // Indeed it wouldn't work well regarding sorting and pagination
        data: matchingReferences && matchingReferences.length > 0
            ? indexById(matchingReferences)
            : {},
        displayedFilters: displayedFilters,
        error: dataStatus.error,
        filterValues: filterValues,
        hasCreate: false,
        hideFilter: hideFilter,
        // For the ListContext, we don't want to always display the selected items first.
        // Indeed it wouldn't work well regarding sorting and pagination
        ids: matchingReferencesIds || EmptyArray,
        loaded: loaded,
        loading: dataStatus.waiting,
        onSelect: onSelect,
        onToggleItem: onToggleItem,
        onUnselectItems: onUnselectItems,
        page: page,
        perPage: perPage,
        refetch: refetch,
        resource: resource,
        selectedIds: input.value || EmptyArray,
        setFilter: setFilter,
        setFilters: setFilters,
        setPage: setPage,
        setPagination: setPagination,
        setPerPage: setPerPage,
        setSort: setSort,
        setSortForList: setSortForList,
        showFilter: showFilter,
        warning: dataStatus.warning,
        total: total,
    };
};
var EmptyArray = [];
// concatenate and deduplicate two lists of records
var mergeReferences = function (ref1, ref2) {
    var res = __spreadArrays(ref1);
    var ids = ref1.map(function (ref) { return ref.id; });
    ref2.forEach(function (ref) {
        if (!ids.includes(ref.id)) {
            ids.push(ref.id);
            res.push(ref);
        }
    });
    return res;
};
var defaultFilterToQuery = function (searchText) { return ({ q: searchText }); };
