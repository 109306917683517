var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Children, cloneElement, isValidElement, useState, } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { escapePath } from 'ra-core';
import { TabbedShowLayoutTabs, getTabFullPath } from './TabbedShowLayoutTabs';
var sanitizeRestProps = function (_a) {
    var children = _a.children, className = _a.className, record = _a.record, resource = _a.resource, basePath = _a.basePath, version = _a.version, initialValues = _a.initialValues, staticContext = _a.staticContext, translate = _a.translate, tabs = _a.tabs, rest = __rest(_a, ["children", "className", "record", "resource", "basePath", "version", "initialValues", "staticContext", "translate", "tabs"]);
    return rest;
};
var useStyles = makeStyles(function (theme) { return ({
    content: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}); }, { name: 'RaTabbedShowLayout' });
/**
 * Tabbed Layout for a Show view, showing fields grouped in tabs.
 *
 * Receives the current `record` from the parent `<Show>` component,
 * and passes it to its children. Children should be Tab components.
 * The component passed as `tabs` props replaces the default material-ui's <Tabs> component.
 *
 * @example
 *     // in src/posts.js
 *     import * as React from "react";
 *     import { Show, TabbedShowLayout, Tab, TextField } from 'react-admin';
 *
 *     export const PostShow = (props) => (
 *         <Show {...props}>
 *             <TabbedShowLayout>
 *                 <Tab label="Content">
 *                     <TextField source="title" />
 *                     <TextField source="subtitle" />
 *                </Tab>
 *                 <Tab label="Metadata">
 *                     <TextField source="category" />
 *                </Tab>
 *             </TabbedShowLayout>
 *         </Show>
 *     );
 *
 *     // in src/App.js
 *     import * as React from "react";
 *     import { Admin, Resource } from 'react-admin';
 *
 *     import { PostShow } from './posts';
 *
 *     const App = () => (
 *         <Admin dataProvider={...}>
 *             <Resource name="posts" show={PostShow} />
 *         </Admin>
 *     );
 *     export default App;
 */
export var TabbedShowLayout = function (props) {
    var basePath = props.basePath, children = props.children, classesOverride = props.classes, className = props.className, record = props.record, resource = props.resource, _a = props.syncWithLocation, syncWithLocation = _a === void 0 ? true : _a, tabs = props.tabs, value = props.value, version = props.version, rest = __rest(props, ["basePath", "children", "classes", "className", "record", "resource", "syncWithLocation", "tabs", "value", "version"]);
    var match = useRouteMatch();
    var classes = useStyles(props);
    var nonNullChildren = Children.toArray(children).filter(function (child) { return child !== null; });
    var _b = useState(0), tabValue = _b[0], setTabValue = _b[1];
    var handleTabChange = function (event, value) {
        if (!syncWithLocation) {
            setTabValue(value);
        }
    };
    return (React.createElement("div", __assign({ className: className, key: version }, sanitizeRestProps(rest)),
        cloneElement(tabs, {
            syncWithLocation: syncWithLocation,
            onChange: handleTabChange,
            value: tabValue,
        }, nonNullChildren),
        React.createElement(Divider, null),
        React.createElement("div", { className: classes.content }, Children.map(nonNullChildren, function (tab, index) {
            return tab && isValidElement(tab) ? (syncWithLocation ? (React.createElement(Route, { exact: true, path: escapePath(getTabFullPath(tab, index, match.url)), render: function () {
                    return cloneElement(tab, {
                        context: 'content',
                        resource: resource,
                        record: record,
                        basePath: basePath,
                    });
                } })) : tabValue === index ? (cloneElement(tab, {
                context: 'content',
                resource: resource,
                record: record,
                basePath: basePath,
            })) : null) : null;
        }))));
};
TabbedShowLayout.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    location: PropTypes.object,
    match: PropTypes.object,
    record: PropTypes.object,
    resource: PropTypes.string,
    syncWithLocation: PropTypes.bool,
    tabs: PropTypes.element,
    value: PropTypes.number,
    version: PropTypes.number,
};
TabbedShowLayout.defaultProps = {
    tabs: React.createElement(TabbedShowLayoutTabs, null),
};
